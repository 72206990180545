<template>
  <div class="book-detail-page">
    <img
      class="img1"
      src="../../../assets/images/bookbag.png"
      alt=""
      width="100%"
    />
    <img
      class="img2"
      src="../../../assets/images/bookimg.png"
      alt=""
      width="100%"
    />
    <div class="contain">
      <div class="left">
        <p class="Rem0_21">联系我们购买书籍</p>
        <img src="../../../assets/images/bookimg2.png" alt="" />
        <p class="Rem0_19">扫一扫添加微信购买</p>
      </div>
      <div class="right">
        <p class="Rem0_30">{{ bookInfo.bookName }}</p>
        <div>
          <div class="book">
            <img :src="bookInfo.img" alt="" />
            <span class="Rem0_24">书价 {{ bookInfo.price }}</span>
          </div>
          <div class="detail">
            <p class="Rem0_20">{{ bookInfo.bookName }}</p>
            <div class="title">
              <span>内容介绍</span>
            </div>
            <div v-html="bookInfo.textContain"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {
  data() {
    return {
      bookInfo: {},
    };
  },
  mounted() {
    this.bookInfo = JSON.parse(this.$route.query.item);
  },
  methods: {},
};
</script>
  
  <style lang="less" scoped>
.book-detail-page {
  position: relative;
  width: 100%;
  padding-top: 60px;
  .img1 {
    position: absolute;
    z-index: 0;
  }
  .img2 {
    position: relative;
    z-index: 9999;
    padding-top: 1%;
  }
  .contain {
    position: relative;
    z-index: 9999;
    display: flex;
    width: 78%;
    margin-left: 13%;
    margin-top: 2%;
    .left {
      width: 20%;
      padding: 0 0.2rem;
      background: #f2faff;
      border: 1px solid #dfdfdf;
      text-align: center;
      margin-right: 3.5%;
      height: 4.7rem;
      img {
        width: 2.8rem;
      }
      p:nth-of-type(1) {
        padding: 0.3rem 0 0.2rem 0;
        color: #000000;
      }

      p:nth-of-type(2) {
        padding: 0.2rem 0;
        color: #858899;
      }
    }
    .right {
      width: 80%;
      background: #ffffff;
      box-shadow: 1px 1px 3px 1px #dadada;
      padding: 0.32rem 0.4rem;
      .Rem0_30 {
        font-weight: bold;
        margin-top: 0.5%;
      }
      > div {
        display: flex;
        .book {
          margin-right: 4%;
          margin-top: 3%;
          text-align: center;
          img {
            width: 2.8rem;
            height: 4.7rem;
          }
          span {
            background: #f8f8f8;
            color: #484848;
            padding: 0.12rem 0.3rem;
          }
        }
        .detail {
          .Rem0_20 {
            font-weight: bold;
            margin-top: 3.5%;
          }
          .title {
            background: #a6a9f5;
            height: 0.3rem;
            margin-top: 3.8%;
            margin-bottom: 2.5%;
            span {
              background: #3264c4;
              margin-left: 2%;
              padding: 0.11rem 0.25rem;
              line-height: 0.28rem;
              font-size: 0.17rem;
              color: white;
            }
          }
          /deep/ .bookText {
            margin-top: 2%;
          }
          /deep/.textIndent,
          /deep/ .bookText {
            text-indent: 0.4rem;
            font-size: 0.18rem;
            color: #333333;
            span {
              margin-right: 0.2rem;
            }
          }
          /deep/ .bookSection {
            font-size: 0.18rem;
            margin-top: 2.5%;
            color: #333333;
            span {
              cursor: default;
            }
            span:nth-of-type(1) {
              margin-right: 4%;
            }
          }
        }
      }
    }
  }
}
</style>